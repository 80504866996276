import { MobileNav } from "./MobileNav/MobileNav";
import { DesktopNav } from "./DesktopNav/DesktopNav";

export const Navbar = ({
  manifestData,
}: Record<string, unknown>): JSX.Element => (
  <header>
    <DesktopNav
      key={"DesktopNav"}
      manifestData={manifestData}
    />
    <MobileNav
      key={"MobileNav"}
      manifestData={manifestData}
    />
    <div
      key={"DummyHeader"}
      id="dummy-header"
      style={{ marginTop: "100px" }}
    ></div>
  </header>
);

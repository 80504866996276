import ImageGallery from "react-image-gallery";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css";

interface Props {
  // How to add to functional equivalent?
  sliderConfig: Record<string, any>;
}

export default function Slider(props: Props): JSX.Element {
  const { sliderConfig } = props;
  const images = sliderConfig.items.map(
    (item: { url: string | undefined; altText: string | undefined }) => {
      return {
        original: item.url,
        originalAlt: item.altText,
        originalClass: "SliderDiv ",
      };
    }
  );

  return (
    <section>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={true}
        slideInterval={sliderConfig.interval ?? 5000}
        showThumbnails={false}
        showNav={false}
        lazyLoad={true}
      />
    </section>
  );
}

import React from "react";
import { Navbar } from "./views/Navbar/Navbar";
import { Outlet } from "react-router";
import Footer from "./views/Footer/Footer";

export const Layout = ({ manifest }: Record<string, unknown>): JSX.Element => {
  return (
    <React.Fragment>
      <Navbar manifestData={manifest} />
      <Outlet />
      <Footer manifestData={manifest} />
    </React.Fragment>
  );
};

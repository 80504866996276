import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { usePageTracking } from "./hooks/usePageTracking";
import { useManifestData } from "./hooks/useManifestData";
import { Helmet } from "react-helmet";
import { Suspense } from "react";
import { Layout } from "./Layout";
import OCVManifest from "./OCVManifest";

export default function App(): JSX.Element {
  usePageTracking();

  const { data } = useManifestData();

  return (
    <ChakraProvider>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href={data?.stylesheet.images.favicon.url}
          sizes="48x48"
        />
      </Helmet>
      <Suspense fallback={<>...</>}>
        <Routes>
          <Route
            path="/"
            element={<Layout manifest={data} />}
          >
            {/* buildMainPage function goes here */}
            <Route
              index
              element={data && OCVManifest.buildMainPage(data)}
            />
            {/* buildRoutes function goes here  */}
            {data && OCVManifest.buildRoutes(data)}
          </Route>
        </Routes>
      </Suspense>
    </ChakraProvider>
  );
}

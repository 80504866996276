import styled from "styled-components";

export interface ColorProps {
  menuColor?: string;
  subColor?: string;
  nestedSubColor?: string;
}

export const Nav = styled.div<{ primary: string; tertiary: string }>`
  height: 100px;
  width: 100%;
  background-color: ${({ primary }) => primary};
  border-bottom: 4px solid ${({ tertiary }) => tertiary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  padding: 0 0.5rem;
  position: fixed;
  top: 0;

  & > a {
    padding: 0;
  }

  @media screen and (min-width: 1150px) {
    display: none;
  }
`;

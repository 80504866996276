import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

const getManifestData = async () => {
  const manifestURL = process.env.REACT_APP_MANIFEST_URL as string;
  const version = process.env.REACT_APP_VERSION as string;
  const build = process.env.REACT_APP_BUILD as string;

  const {
    data: {
      data: {
        manifest,
        lookup: { web },
      },
    },
  } = await axios.get(manifestURL);

  const { baseLayout, features, homeOrder, stylesheet, views } =
    manifest[web[version][build]];

  return {
    baseLayout,
    features,
    homeOrder,
    stylesheet,
    views,
  };
};

export const useManifestData = (): UseQueryResult<{ [key: string]: any }> => {
  return useQuery(["manifest"], () => getManifestData());
};

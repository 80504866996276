import React, { useRef } from "react";
import * as S from "./styles";
import Color from "color";
import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { SocialIcons } from "../../../components/SocialIcons/SocialIcons";
import { NavLink } from "react-router-dom";

export const MobileNav = ({
  manifestData,
}: Record<string, any>): JSX.Element => {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const config: any = manifestData?.["views"]["navbar"]["config"];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | any>();

  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];

  const color1 = Color(primaryColor).lightness(30);
  const subColor = color1.toString();
  const color2 = color1.lightness(25);
  const nestedColor = color2.toString();

  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.Nav
        primary={primaryColor}
        tertiary={tertiaryColor}
      >
        <Link
          cursor="pointer"
          fontFamily="Roboto"
          fontWeight="500"
          textDecoration="none"
          z-index="-1"
          top="10px"
          position="fixed"
          href="/"
        >
          <img
            src={images["sheriffPatch"]["url"]}
            alt={images["sheriffPatch"]["altText"]}
            width="75"
          />
        </Link>
        <IconButton
          z-index="-1"
          position="fixed"
          top="20px"
          right="10px"
          cursor="ponter"
          backgroundColor={primaryColor}
          color="#fff"
          size="lg"
          aria-label="Mobile Navigation"
          onClick={onOpen}
          ref={btnRef}
          fontSize="24px"
          icon={<HamburgerIcon fontSize="24px" />}
          _hover={primaryColor}
        />
        <Drawer
          size="md"
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              pt="2rem"
              fontSize="24px"
              color="#fff"
            />
            <DrawerHeader backgroundColor={primaryColor} />
            <DrawerBody
              pl="0"
              pr="0"
              pt="4rem"
              color="#fff"
              backgroundColor={primaryColor}
            >
              <Accordion
                width="100%"
                pl="0"
                pr="0"
                allowMultiple
                backgroundColor={primaryColor}
                textColor="#fff"
              >
                {config.items?.map((item: any) =>
                  featuresList[item].type === "submenu" ? (
                    <AccordionItem borderBottomWidth="1px">
                      <AccordionButton
                        key={featuresList[item].navHeader}
                        fontWeight="700"
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          {featuresList[item].navHeader}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel
                        backgroundColor={subColor}
                        onClick={onClose}
                      >
                        {featuresList[item]?.dropdown?.map(
                          (dropdownItem: any, index: number) =>
                            featuresList[dropdownItem]?.external === true ? (
                              <Link
                                key={index}
                                textDecorationLine="none"
                                _hover={{ textDecoration: "none" }}
                                href={featuresList[dropdownItem]?.url}
                                isExternal
                              >
                                <Box
                                  width="inherit"
                                  p="1"
                                >
                                  {featuresList[dropdownItem]?.title}
                                </Box>
                              </Link>
                            ) : (
                              //internal link

                              <Link
                                key={index}
                                textDecorationLine="none"
                                _hover={{ textDecoration: "none" }}
                                as={NavLink}
                                to={dropdownItem}
                                onClick={onClose}
                              >
                                <Box
                                  width="inherit"
                                  p="1"
                                  ml="1"
                                >
                                  {featuresList[dropdownItem]?.title}
                                </Box>
                              </Link>
                            )
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  ) : featuresList[item].type === "megaMenu" ? (
                    <AccordionItem borderBottomWidth="1px">
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="700"
                        >
                          {featuresList[item].navHeader}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      {featuresList[item]?.dropdown?.map(
                        (dropdownItem: any, index: number) => (
                          <AccordionPanel
                            pl="0"
                            pr="0"
                            backgroundColor={subColor}
                            key={dropdownItem.columnHeader + index}
                          >
                            <AccordionItem border="0px">
                              <AccordionButton
                                key={item.title}
                                fontWeight="700"
                              >
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  ml="1"
                                >
                                  {dropdownItem.columnHeader}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel
                                pl="0"
                                pr="0"
                                backgroundColor={nestedColor}
                                key={featuresList[item]?.title + index}
                              >
                                {dropdownItem.items?.map(
                                  (item: any, index: number) =>
                                    featuresList[item]?.external === true ? (
                                      //external link
                                      <Link
                                        key={index}
                                        textDecorationLine="none"
                                        _hover={{ textDecoration: "none" }}
                                        href={featuresList[item]?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={onClose}
                                        isExternal
                                      >
                                        <Box
                                          width="inherit"
                                          p="1"
                                          ml="5"
                                        >
                                          {featuresList[item]?.title}
                                        </Box>
                                      </Link>
                                    ) : (
                                      //internal link

                                      <Link
                                        key={index}
                                        textDecorationLine="none"
                                        _hover={{ textDecoration: "none" }}
                                        as={NavLink}
                                        to={item}
                                        onClick={onClose}
                                      >
                                        <Box
                                          width="inherit"
                                          p="1"
                                          ml="5"
                                        >
                                          {featuresList[item]?.title}
                                        </Box>
                                      </Link>
                                    )
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </AccordionPanel>
                        )
                      )}
                    </AccordionItem>
                  ) : (
                    <AccordionItem borderWidth="0">
                      {/* single item */}

                      <AccordionButton
                        key={item.title}
                        fontWeight="700"
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          textDecoration="none"
                        >
                          {featuresList[item]?.external === true ? (
                            // external link
                            <Link
                              textDecorationLine="none"
                              _hover={{ textDecoration: "none" }}
                              href={featuresList[item]?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={onClose}
                              isExternal
                            >
                              <Box width="inherit">
                                {featuresList[item]?.title}
                              </Box>
                            </Link>
                          ) : (
                            // internal link
                            <Link
                              textDecorationLine="none"
                              _hover={{ textDecoration: "none" }}
                              as={NavLink}
                              to={item}
                              onClick={onClose}
                            >
                              <Box width="inherit">
                                {featuresList[item].title}
                              </Box>
                            </Link>
                          )}
                        </Box>
                      </AccordionButton>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            </DrawerBody>
            <DrawerFooter
              justifyContent="space-evenly"
              backgroundColor={primaryColor}
              transition="0.25s"
              color="#f0f0f0"
              alignItems="center"
              padding="1rem"
            >
              {config?.socialMedia?.map(
                (
                  item: { icon: string; url: string; name: string },
                  index: number
                ) => (
                  <Link
                    href={item.url}
                    key={item.icon + index}
                    fontFamily="Roboto"
                    fontWeight="500"
                    textDecoration="none"
                    borderTopColor="tertiaryColor"
                    backgroundColor="tertiaryColor"
                    onClick={onClose}
                  >
                    {SocialIcons(item.icon, "45px")}
                  </Link>
                )
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </S.Nav>
    )
  );
};
